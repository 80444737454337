
import { mapGetters, mapMutations, mapState } from "vuex";
import LanguageSwitcher from "~/components/common/LanguageSwitcher.vue";
import { getElementHeight, topMenuFormatter } from "~/utils";
import CaretRightSvg from "~/components/svg/CaretRightSvg.vue";
import CloseSvg from "~/components/svg/CloseSvg.vue";
import UserSvg from "~/components/svg/UserSvg.vue";
import PinSvg from "~/components/svg/PinSvg.vue";
import UiIcon from "~/components/UI/UiIcon.vue";
import UiButton from "~/components/UI/UiButton.vue";

export default {
  components: {
    UiIcon,
    PinSvg,
    UserSvg,
    CloseSvg,
    CaretRightSvg,
    LanguageSwitcher,
    UiButton,
  },
  props: {
    headerMenu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeMenu: null,
    };
  },
  computed: {
    ...mapGetters({
      getLanguages: "general/getLanguages",
      isAuthenticated: "auth/isAuthenticated",
    }),
    ...mapState({
      isOpenMobileMenu: (state) => state.general.isOpenMobileMenu,
      settings: (state) => state.general.settings,
    }),
  },
  watch: {
    isOpenMobileMenu(val) {
      document.body.style.overflow = val ? "hidden" : "auto";
      const chatIcon = document.querySelector("[data-id='zsalesiq']");
      if (chatIcon) {
        chatIcon.style.display = val ? "none" : "block";
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleMobileMenu: "general/TOGGLE_MOBILE_MENU",
    }),
    getElementHeight,
    getComponentType(link) {
      if (link.is_title) {
        return "div";
      } else {
        return "UiButton";
      }
    },
    setActiveMenu(item) {
      if (item?.id === this.activeMenu?.id) {
        this.activeMenu = null;
        return;
      }
      this.activeMenu = item;
    },
  },
};
